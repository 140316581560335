<template>
  <div ref="root">
    <slot v-if="showFallback" name="fallback">
      <SvgBox
        v-bind="$attrs"
        :width="widthInNumber"
        :height="heightInNumber"
        background-color="gray-500"
      />
    </slot>
    <picture v-else>
      <source
        v-for="(imageSource, index) in imageSources"
        :key="index"
        :type="imageSource.format"
        :srcset="imageSource.source"
        :media="imageSource.mediaQuery"
      />

      <img
        ref="imageEl"
        v-bind="$attrs"
        :key="retryID"
        :src="parsedSrc"
        :alt="alt"
        :width="widthInNumber"
        :height="heightInNumber"
        :loading="lazy ? 'lazy' : undefined"
        :class="{ 'bg-gray-300': !isLoaded }"
        @error="replaceErrorImage"
        @load="onLoad"
      />
    </picture>
  </div>
</template>

<script lang="ts" setup>
import { ref, toRefs, computed, watch, onMounted } from "vue";
import { nanoid } from "nanoid";
import useImageMutator from "~/composables/useImageMutator";
import { isContainHttp, rebuildAssetURL } from "~/helpers/url";
import { generateSourceMediaQuery } from "~/helpers/image";
import SvgBox from "~/components/SvgBox.vue";

// scenario
// 1. local image can pass retina version
// 2. external image can provide single src then use image service to generete webp and retina

export type SrcSet = {
  source: string;
  format?: string;
  breakpoint?: {
    minWidth?: number;
    maxWidth?: number;
  };
  width?: number;
  height?: number;
  pixelDensity?: "1x" | "2x";
  useMutator?: boolean;
};

export type Props = {
  alt: string;
  src: string;
  width: string | number;
  sources?: SrcSet[];
  height?: string | number;
  useMutator?: boolean;
  useBaseURL?: boolean;
  lazy?: boolean;
};

type RetryFunction = NodeJS.Timeout;

const props = withDefaults(defineProps<Props>(), {
  height: "",
  lazy: true,
  useBaseURL: true,
  sources: () => [],
});

type ImageSources = {
  format: string | undefined;
  mediaQuery: string | undefined;
  source: string | undefined;
}[];

const { sources, width, src, height, useMutator } = toRefs(props);
const root = ref<Element | null>(null);
const retryCount = ref(0);
const maxRetryCount = ref(3);
const retryFunction = ref<undefined | RetryFunction>();
const retryID = ref(src.value);
const retryInterval = 1000;
const showFallback = ref(false);
const isLoaded = ref(false);
const imageEl = ref<HTMLImageElement | null>(null);

const widthInNumber =
  typeof width.value === "string"
    ? Math.round(parseFloat(width.value))
    : typeof width.value === "number"
    ? Math.round(width.value)
    : 0;
const heightInNumber =
  typeof height.value === "string" && height.value.length
    ? Math.round(parseFloat(height.value))
    : typeof height.value === "number"
    ? Math.round(height.value)
    : 0;
const imageSources = ref<ImageSources>([]);

const isLocal = computed(() => !isContainHttp(src.value) && !useMutator.value);

const parsedSrc = computed(() => {
  return isLocal.value
    ? src.value
    : props.useBaseURL && props.useMutator
    ? rebuildAssetURL(src.value)
    : src.value;
});

function generateDefaultPreset() {
  const sources: ImageSources = [];

  const webpImage = useImageMutator({
    image: parsedSrc.value,
    width: widthInNumber,
    height: heightInNumber,
    isWebp: true,
  });

  const webpImageRetina = useImageMutator({
    image: parsedSrc.value,
    width: widthInNumber * 2,
    height: heightInNumber * 2,
    isWebp: true,
  });
  const webpImageRetina2 = useImageMutator({
    image: parsedSrc.value,
    width: widthInNumber * 3,
    height: heightInNumber * 3,
    isWebp: true,
  });
  const webpImageRetina3 = useImageMutator({
    image: parsedSrc.value,
    width: widthInNumber * 4,
    height: heightInNumber * 4,
    isWebp: true,
  });
  const finalImgSrc = `${webpImage} 1x, ${webpImageRetina} 2x, ${webpImageRetina2} 3x, ${webpImageRetina3} 4x`;
  sources.push({
    format: "image/webp",
    mediaQuery: undefined,
    source: finalImgSrc,
  });

  return sources;
}

function init() {
  showFallback.value = false;
  if (Array.isArray(sources?.value) && sources?.value?.length) {
    sources.value.forEach((imgSource) => {
      const usedWidth = imgSource.width || 0;
      const usedHeight = imgSource.height || 0;
      let source = imgSource.source;
      if (imgSource.useMutator) {
        const parsedImage = rebuildAssetURL(imgSource.source);
        const resizedRetinaImage = useImageMutator({
          image: parsedImage,
          width: usedWidth * 2,
          height: usedHeight * 2,
          isWebp: true,
        });
        const resizedImage = useImageMutator({
          image: parsedImage,
          width: usedWidth,
          height: usedHeight,
          isWebp: imgSource.format === "webp",
        });

        source = `${resizedImage} 1x, ${resizedRetinaImage} 2x`;
      }
      imageSources.value.push({
        format: imgSource.format ? `image/${imgSource.format}` : undefined,
        mediaQuery: imgSource.breakpoint
          ? generateSourceMediaQuery({
              maxWidth: imgSource.breakpoint.maxWidth,
              minWidth: imgSource.breakpoint.minWidth,
            })
          : undefined,
        source,
      });
    });
    return;
  }
  // for use case #2
  if (!isLocal.value) {
    imageSources.value = [];
    if (useMutator.value) {
      const generateDefault = generateDefaultPreset();
      generateDefault.forEach((source) => {
        imageSources.value.push(source);
      });
    }
  }
}

function onLoad() {
  isLoaded.value = true;
}

function replaceErrorImage() {
  if (retryCount.value < maxRetryCount.value) {
    retryFunction.value = setTimeout(() => {
      retryID.value = parsedSrc.value + nanoid(3);
      retryCount.value += 1;
    }, retryInterval);
  } else {
    console.log(
      `error using image ${parsedSrc.value} now using image fallback`
    );
    showFallback.value = true;
  }
}

watch(
  () => props.src,
  () => {
    init();
  },
  { immediate: true }
);

onMounted(() => {
  if (imageEl.value?.complete) {
    onLoad();
  }
});
</script>

<script lang="ts">
export default {
  name: "HhImage",
  inheritAttrs: false,
};
</script>
